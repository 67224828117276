import styled from "styled-components";
import Button from "~/components/ui/Button";
import { getColor, getColorWithAlpha, responsive } from "~/helpers/style";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`;

export const Label = styled.div<{ checked: boolean }>`
  && {
    width: 100%;
    color: ${getColor("text")};

    flex-direction: column;
    align-items: flex-start;
    line-height: 25.48px;
    margin-bottom: 0;
    font-weight: ${(props) => (props.checked ? "600" : "500")};

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
  }

  .all-regions {
    color: ${getColorWithAlpha("text", 0.4)};
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 182%;
  }
`;

export const RadioContainer = styled.div<{ checked: boolean }>`
  > .radio-container > label > div > label {
  }
`;

export const CheckoutButton = styled(Button)`
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 100%;

  justify-content: center;
  ${responsive("mobileL")} {
    justify-content: unset;
    width: unset;
  }
`;
