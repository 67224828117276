import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompetitorFilters } from "~/redux/types";

type SetFilterPayload = {
  key: keyof Partial<CompetitorFilters>;
  value:
    | CompetitorFilters[keyof CompetitorFilters]
    | { startDate: any; endDate: any };
};

const initialState: CompetitorFilters = {
  searchTerm: "",
  launchDateForShop: { endDate: "", startDate: "" },
  stockStatus: "All Dates",
  priceRange: { min: 0, max: 0 },
  salesVolume: { min: 0, max: 0 },
  domainTLD: ".com",
  storeCurrency: "USD",
  storeLanguage: "English",
  selectedCategories: [],
  keywordFilters: {
    includeTitleKeywords: [],
    excludeTitleKeywords: [],
    includeDescriptionKeywords: [],
    excludeDescriptionKeywords: [],
    includeDomainKeywords: [],
    excludeDomainKeywords: [],
  },
};

const competitorSlice = createSlice({
  name: "competitorFilters",
  initialState,
  reducers: {
    loadFilters: (state, action: PayloadAction<Partial<CompetitorFilters>>) => {
      state.keywordFilters =
        action.payload.keywordFilters || initialState.keywordFilters;
      state.priceRange = action.payload.priceRange || initialState.priceRange;

      state.salesVolume =
        action.payload.salesVolume || initialState.salesVolume;
      state.domainTLD = action.payload.domainTLD || initialState.domainTLD;
      state.storeCurrency =
        action.payload.storeCurrency || initialState.storeCurrency;
      state.storeLanguage =
        action.payload.storeLanguage || initialState.storeLanguage;
      state.launchDateForShop =
        action.payload.launchDateForShop || initialState.launchDateForShop;
    },
    resetFilters: () => initialState,
    setFilter: (state: any, action: PayloadAction<SetFilterPayload>) => {
      const { key, value } = action.payload;
      if (key === "priceRange") {
        state.priceRange = value;
      }
      if (key === "salesVolume") {
        state.salesVolume = value;
      }

      state[key] = value as CompetitorFilters[keyof CompetitorFilters];
    },
    toggleCategory: (state, action: PayloadAction<string>) => {
      const category = action.payload;
      const index = state.selectedCategories.indexOf(category);

      if (index === -1) {
        state.selectedCategories.push(category);
      } else {
        state.selectedCategories.splice(index, 1);
      }
    },
    setKeywords: (
      state,
      action: PayloadAction<{
        key: keyof CompetitorFilters["keywordFilters"];
        value: string[];
      }>
    ) => {
      state.keywordFilters[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setFilter,
  toggleCategory,
  setKeywords,
  resetFilters,
  loadFilters,
} = competitorSlice.actions;
export default competitorSlice.reducer;
