import { IRootPaths } from "~/types/routing";

import { paths as auth } from "~/router/paths/auth";
import { paths as app } from "~/router/paths/app";
import { paths as settings } from "~/router/paths/settings";
import { paths as checkout } from "~/router/paths/checkout";
import { paths as adSpyTool } from "~/router/paths/adSpyTool";
import { paths as productDatabase } from "~/router/paths/productDatabase";
import { paths as salesTracker } from "~/router/paths/salesTracker";
import { paths as productPortfolio } from "~/router/paths/productPortfolio";
import { paths as competitorResearch } from "~/router/paths/competitorResearch";

export const paths: IRootPaths = {
  auth,
  app,
  checkout,
  settings,
  adSpyTool,
  salesTracker,
  productDatabase,
  productPortfolio,
  competitorResearch,
};
