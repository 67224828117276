import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { faBars, faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core"; // Import IconProp for FontAwesome icons

import { Userpilot } from "userpilot";
import { paths } from "~/router/paths";
import { navItems } from "~/constants/sidebar";
import { DISABLE_PAYMENTS } from "~/helpers/plans";

import { useStore } from "~/hooks/useStore";
import { useAccount } from "~/hooks/useAccount";
import { useMediaQuery } from "~/hooks/useMediaQuery";

import * as S from "./Sidebar.style";
import Button from "~/components/ui/Button";
import FlexContainer from "~/components/ui/FlexContainer";
import { SVG } from "~/components/ui/SVG";
import { SVGIcon } from "~/components/ui/SVG/types";
import { StyledLink } from "~/components/ui/Styled/Link";
import { Notifications } from "~/components/notifications";
import { HelpCenterModal } from "~/components/helpCenterModal";

import { UseQueryResult } from "@tanstack/react-query";

// @ts-ignore
import { ReactComponent as LogoSvg } from "~/assets/svg/logo.svg";

export interface INavItem {
  namePath: string;
  path?: string;
  icon?: SVGIcon; // Use IconProp for FontAwesome icons
  openInNewTab?: boolean;
  getCountQuery?: () => UseQueryResult<{ count: number }, unknown>;
  showHelpCenterModal?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children?: INavItem[];
  sectionLabel?: string;
}

Userpilot.initialize("NX-f9dabb4f");

export const Brand = () => (
  <S.LinkContainer>
    <StyledLink to={paths.app.home}>
      <S.BrandContainer>
        <LogoSvg style={{ height: 40, width: "100%" }} />
      </S.BrandContainer>
    </StyledLink>
  </S.LinkContainer>
);

interface MenuItemProps extends INavItem {
  openHelpCenter?: () => void;
  closeMobileSidebar?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  path,
  openInNewTab,
  icon,
  sectionLabel,
  namePath,
  showHelpCenterModal,
  openHelpCenter,
  closeMobileSidebar,
  onClick,
  children,
  getCountQuery,
}) => {
  const { t } = useTranslation();

  const { store } = useStore();
  const queryResult = getCountQuery?.();
  const count = queryResult?.data?.count ?? 0;
  const [isOpen, setIsOpen] = useState(false);

  if (store && namePath === "nav.connect-store") {
    return null;
  }

  const buttonStyle = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: "100%",
  };

  const childrenButtonStyle = {
    ...buttonStyle,
    marginBottom: 5,
  };

  const renderChildren = () => (
    <S.RenderContainer>
      {children?.map((childItem, idx) => (
        <S.NavItem
          key={idx}
          to={childItem.path ?? "#"}
          end
          target={childItem.openInNewTab ? "_blank" : undefined}
          rel={childItem.openInNewTab ? "noopener noreferrer" : undefined}
          style={{ textDecoration: "none", width: "100%" }}
          onClick={(e) => {
            closeMobileSidebar?.();
            if (showHelpCenterModal && openHelpCenter) {
              e.preventDefault();
              openHelpCenter();
            }
            if (onClick) {
              e.preventDefault();
              onClick(e);
            }
          }}>
          {({ isActive }) => (
            <Button
              data-ischildren={true}
              variant={isActive ? "filled" : "default"}
              color={isActive ? "primary" : "text"}
              fontWeight={isActive ? 600 : 500}
              bgColor={isActive ? "primaryLight" : "white"}
              radius={0.75}
              gap="20px"
              style={buttonStyle}
              justifyContent="flex-start">
              {t(childItem.namePath)}
            </Button>
          )}
        </S.NavItem>
      ))}
    </S.RenderContainer>
  );

  return (
    <Fragment>
      {sectionLabel && <S.MenuItemTitle>{t(sectionLabel)}</S.MenuItemTitle>}
      {children?.length ? (
        <>
          <S.ButtonWithChildren
            variant="default"
            color="text"
            fontWeight={500}
            bgColor="white"
            radius={0.75}
            gap="26px"
            style={childrenButtonStyle}
            justifyContent="flex-start"
            fontSize={"15px"}
            onClick={() => setIsOpen(!isOpen)}>
            {icon && <SVG icon={icon} svgProp={{ width: 18 }} />}
            {t(namePath)}

            <S.CollapseIcon isOpen={isOpen}>
              <SVG icon={faChevronDown} svgProp={{ width: 12 }} />
            </S.CollapseIcon>
          </S.ButtonWithChildren>
          {isOpen && renderChildren()}
        </>
      ) : (
        <S.NavItem
          to={path ?? "#"}
          end
          target={openInNewTab ? "_blank" : undefined}
          rel={openInNewTab ? "noopener noreferrer" : undefined}
          onClick={(e) => {
            closeMobileSidebar?.();
            if (showHelpCenterModal && openHelpCenter) {
              e.preventDefault();
              openHelpCenter();
            }
            if (onClick) {
              e.preventDefault();
              onClick(e);
            }
          }}>
          {({ isActive }) => (
            <Button
              variant={isActive ? "filled" : "default"}
              color={isActive ? "primary" : "text"}
              fontWeight={isActive ? 600 : 500}
              bgColor={isActive ? "primaryLight" : "white"}
              radius={0.75}
              gap="26px"
              style={buttonStyle}
              justifyContent="flex-start">
              {icon && <SVG icon={icon} svgProp={{ width: 18 }} />}
              {t(namePath)}
            </Button>
          )}
        </S.NavItem>
      )}
    </Fragment>
  );
};

const Menu: React.FC<{ closeMobileSidebar?: () => void }> = ({
  closeMobileSidebar,
}) => {
  const location = useLocation();
  const { account } = useAccount();
  const [showHelpCenter, setShowHelpCenter] = useState(false);

  useEffect(() => {
    if (account) {
      Userpilot.reload();
    }
  }, [account, location]);

  const settingsIndex = useMemo(
    () =>
      navItems.findIndex(({ path }) =>
        DISABLE_PAYMENTS
          ? path === paths.settings.account
          : path === paths.settings.plans
      ),
    []
  );

  const [itemsBeforeSettings, itemsAfterSettings] = useMemo(() => {
    if (settingsIndex < 0) return [navItems, []];
    return [navItems.slice(0, settingsIndex), navItems.slice(settingsIndex)];
  }, [settingsIndex]);

  const helpCenterPath = useMemo(
    () => navItems.find(({ showHelpCenterModal }) => showHelpCenterModal)?.path,
    []
  );

  return (
    <S.NavContainer>
      <FlexContainer
        height="100%"
        width="100%"
        flexDirection="column"
        justifyContent="space-between">
        <S.MenuItensContainer gap={0}>
          {itemsBeforeSettings.map((navItem) => (
            <MenuItem
              key={navItem.namePath}
              closeMobileSidebar={closeMobileSidebar}
              {...navItem}
            />
          ))}
        </S.MenuItensContainer>

        <S.MenuItensContainer>
          {itemsAfterSettings.map((navItem) => (
            <MenuItem
              key={navItem.namePath}
              openHelpCenter={() => setShowHelpCenter(true)}
              closeMobileSidebar={closeMobileSidebar}
              {...navItem}
            />
          ))}
        </S.MenuItensContainer>
      </FlexContainer>

      <HelpCenterModal
        path={String(helpCenterPath)}
        isShowing={showHelpCenter}
        hide={() => setShowHelpCenter(false)}
      />
    </S.NavContainer>
  );
};

const DesktopSidebar: React.FC = () => (
  <S.SidebarContainer>
    <Brand />
    <Menu />
  </S.SidebarContainer>
);

const MobileSidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.SidebarContainer>
      <Notifications />
      <Brand />
      <S.MobileMenuButton onClick={() => setIsOpen(!isOpen)}>
        <SVG icon={faBars as SVGIcon} size="lg" />{" "}
      </S.MobileMenuButton>
      {isOpen && (
        <S.MobileMenu>
          <Menu closeMobileSidebar={() => setIsOpen(false)} />
        </S.MobileMenu>
      )}
    </S.SidebarContainer>
  );
};

const Sidebar: React.FC = () => {
  const isTabletAndUp = useMediaQuery("tablet");
  return isTabletAndUp ? <DesktopSidebar /> : <MobileSidebar />;
};

export default Sidebar;
