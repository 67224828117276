import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import { ThemeProvider } from "styled-components";
import theme from "~/constants/theme";

import GlobalStyle from "~/globalStyle";

import "@fontsource-variable/inter/slnt.css";
import { ToastContainer } from "~/components/toast";

import { routes } from "~/router/routes";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "~/translations/i18n";

import { analyticsSetup, getCreateRouterWithSentry } from "./helpers/analytics";
import { intercomKey } from "./helpers/environment";
import { ContextWrapper } from "./contexts";

import SuspenseFallback from "~/components/layout/SuspenseFallback";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { GoogleTagManager } from "./components/google-tag-manager";

analyticsSetup();

const createRouter = getCreateRouterWithSentry();
const router = createRouter(routes);

const isProduction = import.meta.env.PROD;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <IntercomProvider appId={intercomKey} autoBoot={false}>
        <ContextWrapper>
          <ThemeProvider theme={theme}>
            {isProduction ? <GoogleTagManager /> : null}
            <GlobalStyle />
            <Suspense fallback={<SuspenseFallback />}>
              <RouterProvider router={router} />
            </Suspense>
            <ToastContainer />
            <ReactQueryDevtools initialIsOpen={false} />
          </ThemeProvider>
        </ContextWrapper>
      </IntercomProvider>
    </Provider>
  </React.StrictMode>
);
