import { lazy } from "react";
import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const AdSpyTool = lazy(() => import("~/pages/ad-spy-tool"));
const AdInfo = lazy(() => import("~/pages/ad-spy-tool/components/AdInfo"));

export const routes: Array<any> = [
  {
    path: paths.adSpyTool.index,
    element: app(AdSpyTool),
  },
  {
    path: paths.adSpyTool.adInfo,
    element: app(AdInfo),
  },
];
