import { IPaths } from "~/types/routing";

const BASE_PATH = "/product-portfolio";

export const paths: IPaths = {
  myPortfolioRoot: `${BASE_PATH}/my-portfolio`,
  myPortfolioProductsList: `${BASE_PATH}/my-portfolio/products/:id`,
  myPortfolioProductDetails: `${BASE_PATH}/my-portfolio/products/:id/product/:productId`,

  collectionsRoot: `${BASE_PATH}/collections`,
  collectionsProductsList: `${BASE_PATH}/collections/products`,
  collectionsProductDetails: `${BASE_PATH}/collections/products/:id`,
};
