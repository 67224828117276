import { useMediaQuery } from "~/hooks/useMediaQuery";
import * as S from "./styles";

export type SeparatorType = "horizontal" | "vertical";

type Props = {
  type: SeparatorType;
  margin?: number;
  className?: string;
  color?: string;
  height?: string;
  alignSelf?: string;
};

const Separator = ({
  type,
  margin = 0,
  className,
  color,
  height,
  alignSelf,
}: Props) => {
  if (type === "horizontal")
    return (
      <S.HorizontalSeparator
        height={height}
        alignSelf={alignSelf}
        className={className}
        margin={margin}
      />
    );
  return (
    <S.VerticalSeparator
      height={height}
      alignSelf={alignSelf}
      color={color}
      className={className}
      margin={margin}
    />
  );
};

export default Separator;
