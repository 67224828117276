import { makeRedirects } from "~/helpers/routes";

import { routes as app } from "~/router/routes/app";
import { routes as auth } from "~/router/routes/auth";
import { routes as settings } from "~/router/routes/settings";
import { routes as checkout } from "~/router/routes/checkout";
import { routes as adSpyTool } from "~/router/routes/adSpyTool";
import {routes as productPortfolio} from "~/router/routes/productPortfolio";
import { DISABLE_PAYMENTS } from "~/helpers/plans";

export const routes = [
  ...app,
  ...auth,
  ...settings,
  ...adSpyTool,
  ...productPortfolio,
  ...(DISABLE_PAYMENTS ? [] : checkout),
  ...makeRedirects(),
];
