import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AliExpressProduct } from "~/types/aliexpressProduct";

interface DropsProductsState {
  products: AliExpressProduct[];
  isLoading: boolean;
  error: string | null;
}

const initialState: DropsProductsState = {
  products: [],
  isLoading: false,
  error: null,
};

const dropsProductsSlice = createSlice({
  name: "dropsProducts",
  initialState,
  reducers: {
    fetchProductsStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchProductsSuccess: (
      state,
      action: PayloadAction<AliExpressProduct[]>
    ) => {
      state.products = action.payload;
      state.isLoading = false;
    },
    fetchProductsFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchProductsStart,
  fetchProductsSuccess,
  fetchProductsFailure,
} = dropsProductsSlice.actions;
export default dropsProductsSlice.reducer;
