import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Label } from "./styles";
import { Radio } from "../radio";
import { StepComponentProps } from "../steps";
import { useMediaQuery } from "~/hooks/useMediaQuery";

const TOOLS_ARRAY = [
  {
    id: "product-database",
    label: "onboarding.option_product_database",
  },
  {
    id: "product-portfolio",
    label: "onboarding.option_product_portfolio",
  },
  {
    id: "competitor-research",
    label: "onboarding.option_competitor_research",
  },
  {
    id: "ad-spy-tool",
    label: "onboarding.option_ad_spy_tool",
  },
  {
    id: "sales-tracker",
    label: "onboarding.option_sales_tracker",
  },
  {
    id: "all-of-them",
    label: "onboarding.option_all_of_them",
  },
];

type ToolSelectionProps = StepComponentProps & {};

export const ToolSelection = ({
  onboardingChoices,
  setOnboardingChoices,
}: ToolSelectionProps) => {
  const { t } = useTranslation();
  const isAboveMobile = useMediaQuery("mobileL");

  const selectedTools = onboardingChoices?.selectedTool || ["all-of-them"];

  const handleSelectTool = (toolId: string) => {
    if (toolId === "all-of-them") {
      setOnboardingChoices((prev) => ({
        ...prev,
        selectedTool: [toolId],
      }));
    } else {
      setOnboardingChoices((prev) => {
        const newSelected =
          prev?.selectedTool?.filter((id) => id !== "all-of-them") || [];
        const alreadySelected = newSelected.includes(toolId);
        const updatedSelection = alreadySelected
          ? newSelected.filter((id) => id !== toolId)
          : [...newSelected, toolId];
        return {
          ...prev,
          selectedTool: updatedSelection,
        };
      });
    }
  };

  return (
    <Container data-testid="tool-selection">
      {TOOLS_ARRAY.map((tool) => {
        const { id: toolId, label } = tool;
        const checked = selectedTools.includes(toolId);

        return (
          <Radio
            key={toolId}
            context="tool-selection"
            optionId={toolId}
            checked={checked}
            isFullWidth={!isAboveMobile}
            label={<Label checked={checked}>{t(label)}</Label>}
            useCheckbox={toolId !== "all-of-them"}
            changeSeletedOption={handleSelectTool}
          />
        );
      })}
    </Container>
  );
};