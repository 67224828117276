import { lazy } from "react";
import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const myPortfolio = lazy(
  () => import("~/pages/product-portfolio/my-portfolio")
);
const myPortfolioProductsList = lazy(
  () => import("~/pages/product-portfolio/my-portfolio/ProductsList")
);
const myPortfolioProductDetails = lazy(
  () => import("~/pages/product-portfolio/my-portfolio/ProductDetails")
);
const portfolioCollections = lazy(
  () => import("~/pages/product-portfolio/collections")
);
const collectionsProductsList = lazy(
  () => import("~/pages/product-portfolio/collections/ProductsList")
);

export const routes: Array<any> = [
  {
    path: paths.productPortfolio.myPortfolioRoot,
    element: app(myPortfolio),
  },
  {
    path: paths.productPortfolio.myPortfolioProductsList,
    element: app(myPortfolioProductsList),
  },
  {
    path: paths.productPortfolio.myPortfolioProductDetails,
    element: app(myPortfolioProductDetails),
  },
  {
    path: paths.productPortfolio.collectionsRoot,
    element: app(portfolioCollections),
  },
  {
    path: paths.productPortfolio.collectionsProductsList,
    element: app(collectionsProductsList),
  },
];
