import { configureStore } from "@reduxjs/toolkit";
import productsDatabaseFiltersSlice from "./slices/productDatabase";
import competitorSlice from "./slices/competitor";
import dropsProductsSlice from "./slices/dropsProducts";

export const store = configureStore({
  reducer: {
    productsDatabaseFilters: productsDatabaseFiltersSlice,
    competitorFilters: competitorSlice,
    dropsProductsSlice: dropsProductsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
