import { DefaultTFuncReturn } from "i18next";
import { toast } from "~/components/toast";
import { IToastOptions, ToastId, ToastType } from "~/components/toast/types";


const fromToastMessage = (
  type: ToastType,
  message?:
    | DefaultTFuncReturn
    | { body: React.ReactNode; options?: IToastOptions },
  toastId?: ToastId
) => {
  if (!message) {
    if (toastId) {
      toast.dismiss(toastId);
    }

    return;
  }

  if (typeof message === "string") {
    return toast.updateOrCreate(toastId, {
      type,
      render: message,
    });
  }

  return toast.updateOrCreate(toastId, {
    type,
    render: message.body,
    ...message.options,
  });
};

export default fromToastMessage;
