import styled, { css } from "styled-components";
import { getColor, getColorWithAlpha, responsive } from "~/helpers/style";

export const RadioContainer = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${(props) => (props.isFullWidth ? "100%" : "calc(50% - 6px)")};
  gap: 20px;

  ${responsive("tablet")} {
    gap: 12px;
  }

  input[type="radio"] {
    opacity: 0;
    position: absolute;
  }
`;

export const Tab = styled.label<{ checked: boolean }>`
  height: 76px;
  border: 1px solid
    ${(props) =>
      props.checked ? getColor("primary") : getColorWithAlpha("primary", 0.1)};

  margin-bottom: 0;
  display: inline-flex;
  flex: 1;
  align-items: center;
  /* justify-content: space-between; */
  &,
  label {
    cursor: pointer;
    //color: ${getColor("primary")};
  }

  padding: 14px 16px;
  border-radius: 6px;
  min-width: 100%;

  ${responsive("tablet")} {
    min-width: auto;
  }

  ${responsive("mobileL")} {
    padding: 10px 76px 10px 15px;
    border-radius: 8px;
  }
`;

export const RadioWrapper = styled.div`
  width: 16px;
  height: 16px;
  overflow: hidden;
  position: relative;
  float: left;
  margin-right: 12px;
`;

export const Radio = styled.div<{ checked: boolean }>`
  display: block;
  position: absolute;

  ${(props) =>
    props.checked
      ? css`
          border: 2px solid ${getColor("primary")};
        `
      : css`
          border: 4px solid ${getColorWithAlpha("text", 0.1)};
        `}

  border-radius: 100%;
  height: 16px;
  width: 16px;
  top: 0px;
  left: 0px;
  z-index: 1;
  margin-bottom: 0;

  &::before {
    display: block;
    opacity: 0;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 2px;
    left: 15px;
    margin: auto;

    background: ${() => getColor("primary")};
    transition: opacity 0.25s linear;
    ${(props) =>
      props.checked &&
      `
        opacity: 1;
        top: calc(50% - 4px);
        left: calc(50% - 4px);
      `}
  }
`;

export const TabContent = styled.div`
  width: calc(100% - 32px);
  float: left;
`;

export const Label = styled.label<{ checked: boolean }>`
  width: 100%;
  color: ${getColor("primary")};
  font-size: 14px;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  font-weight: ${(props) => (props.checked ? "800" : "500")};

  & span {
    color: ${getColorWithAlpha("primary", 0.4)};
  }
`;
