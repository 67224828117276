import { Dispatch, SetStateAction } from "react";
import { IOnboardingChoices } from "~/types/account";
import { ToolSelection } from "./ToolSelection";

export type StepComponentProps = {
  onboardingChoices: IOnboardingChoices | undefined;
  setOnboardingChoices: Dispatch<
    SetStateAction<IOnboardingChoices | undefined>
  >;
};

interface Step {
  titleKey: string;
  component: (props: StepComponentProps) => JSX.Element | null;
}

export const steps: Step[] = [
  {
    titleKey: "onboarding.tool_searching",
    component: ToolSelection,
  },
];
