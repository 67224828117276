import { IPaths } from "~/types/routing";

export const paths: IPaths = {
  home: "/",
  adSpyTool: "/ad-spy-tool",
  productDatabase: "/product-database",
  // productDatabaseDetails: "/product-database/:productId",
  competitorResearchDetails: "/competitor-research/:productId",
  competitorResearch: "/competitor-research",
  myPortfolio: "/product-portfolio/my-portfolio",
  collections: "/product-portfolio/collections",
  salesTracker: "/sales-tracker",
  myTrackings: "/sales-tracker/my-trackings",
  myTrackingStores: "/sales-tracker/my-trackings/stores",
  myTrackingProducts: "/sales-tracker/my-trackings/products",
  myTrackingStoreDetail: "/sales-tracker/my-trackings/store/:storeId",
  myTrackingProductDetail: "/sales-tracker/my-trackings/product/:productId",
  orders: "/orders",
  sampleOrders: "/sample-orders",
  customization: "/customization",
  importList: "/import-list",
  liveProducts: "/live-products",
  brandedInvoice: "/branded-invoice",
  cancel: "/cancel",
  topStores: "/sales-tracker/top-stores",
  topProducts: "/sales-tracker/top-products",
};
