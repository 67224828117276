import React, { ReactNode } from "react";

// Style
import {
  RadioContainer,
  Tab,
  TabContent,
  RadioWrapper,
  Radio as StyledRadio,
  Label,
} from "./styles";
import CustomCheckbox from "~/components/ui/Checkbox";

type OnboardingRadioProps = {
  context: string;
  optionId: string;
  checked: boolean;
  label: string | ReactNode;
  changeSeletedOption: (optionId: string) => void;
  isFullWidth?: boolean;
  hasUniqueLabel?: boolean;
  uniqueLabel?: ReactNode | null;
  useCheckbox?: boolean;
};

export const Radio = ({
  context,
  optionId,
  checked,
  label,
  changeSeletedOption,
  isFullWidth = false,
  hasUniqueLabel = false,
  uniqueLabel,
  useCheckbox
}: OnboardingRadioProps) => (
  <RadioContainer
    className="radio-container"
    isFullWidth={isFullWidth}
    data-testid="onboarding-radio">
    <input
      id={optionId}
      type="radio"
      name={optionId}
      value={optionId}
      onChange={() => changeSeletedOption(optionId)}
    />
    <Tab
      data-heap={`onboarding-flow-${context}-${optionId}`}
      htmlFor={optionId}
      onClick={() => changeSeletedOption(optionId)}
      checked={checked}>
      <RadioWrapper>
        {useCheckbox ? <CustomCheckbox
          checked={checked}
          onChange={() => changeSeletedOption(optionId)}
        /> : <StyledRadio checked={checked} />}
      </RadioWrapper>
      <TabContent>
        {hasUniqueLabel ? (
          uniqueLabel
        ) : (
          <Label checked={checked}>{label}</Label>
        )}
      </TabContent>
    </Tab>
  </RadioContainer>
);
