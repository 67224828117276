import { useEffect } from "react";
import gtmParts from "react-google-tag-manager";
import { googleTagManagerKey } from "~/helpers/environment";

type GoogleTagManagerProps = {
  scriptId?: string;
  dataLayerName?: string;
  additionalEvents?: Record<string, unknown>;
};

export const GoogleTagManager = ({
  scriptId,
  dataLayerName,
  additionalEvents,
}: GoogleTagManagerProps) => {
  if (!googleTagManagerKey) return null;

  const sId = scriptId || "react-google-tag-manager-gtm";

  useEffect(() => {
    const gtmScriptNode = document.getElementById(sId);
    if (!gtmScriptNode?.textContent) return;
    eval(gtmScriptNode.textContent);
  }, []);

  const gtm = gtmParts({
    id: googleTagManagerKey,
    dataLayerName: dataLayerName || "dataLayer",
    additionalEvents: additionalEvents || {},
  });

  return (
    <div>
      <div>{gtm.noScriptAsReact()}</div>
      <div id={sId}>{gtm.scriptAsReact()}</div>
    </div>
  );
};
