// ~/constants/sidebar.ts
import {
  faCog,
  faLifeRing,
  faCartShopping,
  faCartCircleExclamation,
  faBullhorn,
  faBox,
  faHouse,
  faArrowTrendUp,
  faStore,
} from "@fortawesome/pro-light-svg-icons";
import { paths } from "~/router/paths";
import { INavItem } from "~/types/routing";
import { DISABLE_PAYMENTS } from "~/helpers/plans";
import {
  triggerShowConnectStoreModal,
} from "~/helpers/customEvents";

export const navItems: INavItem[] = [
  {
    sectionLabel: "nav.title_dashboard",
    namePath: "nav.title_dashboard",
    path: paths.app.home,
    icon: faHouse,
  },
  {
    namePath: "nav.ad_spy_tool",
    path: paths.app.adSpyTool,
    icon: faBullhorn,
  },
  {
    sectionLabel: "nav.title_product",
    namePath: "nav.product_database",
    path: paths.app.productDatabase,
    icon: faCartShopping,
  },
  {
    // Product Portfolio is now a parent item
    namePath: "nav.product_portfolio",
    icon: faBox,
    children: [
      {
        namePath: "nav.my_portfolio",
        path: paths.productPortfolio.myPortfolioRoot,
      },
      {
        namePath: "nav.collections",
        path: paths.productPortfolio.collectionsRoot,
      },
    ],
  },
  {
    namePath: "nav.competitor_research",
    path: paths.app.competitorResearch,
    icon: faCartCircleExclamation,
  },
  {
    // Sales Tracker is also a parent item
    namePath: "nav.sales_tracker",
    icon: faArrowTrendUp,
    children: [
      {
        namePath: "nav.my_trackings",
        path: paths.app.myTrackingStores,
      },
      {
        namePath: "nav.top_stores",
        path: paths.app.topStores,
      },
      {
        namePath: "nav.top_products",
        path: paths.app.topProducts,
      },
    ],
  },
  {
    namePath: "settings.title",
    path: DISABLE_PAYMENTS ? paths.settings.account : paths.settings.plans,
    icon: faCog,
  },
  {
    namePath: "nav.connect-store",
    path: "/connect-store",
    icon: faStore,
    onClick: () => triggerShowConnectStoreModal(),
  },
  {
    namePath: "nav.help_center",
    path: "HELP CENTER ARTICLE LINK HERE",
    openInNewTab: true,
    icon: faLifeRing,
  },
];
